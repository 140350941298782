import React, { Component } from 'react'
import Script from 'react-load-script'
import Loader from 'react-loader-spinner'

class TpoConnectContainer extends Component {
    state = {
      infoText: 'Syncing with Reggora... ',
      spinnerVisibility: true,
      tpoScriptUrl: 'https://' + this.props.match.params.id + '.encompasstpoconnect.com/scripts/elli-ssf-guest.js',
    }

    render() {
      return <div>
            <h1> {this.state.infoText}</h1>
            {this.state.spinnerVisibility
              ? <Loader
                    type="Puff"
                    color="#00BFFF"
                    height={100}
                    width={100}
                />
              : null
            }
            <Script
                url={this.state.tpoScriptUrl}
                onCreate={this.handleScriptCreate.bind(this)}
                onError={this.handleScriptError.bind(this)}
                onLoad={this.handleScriptLoad.bind(this)}
            />
        </div>
    }

    handleScriptCreate() {
      this.setState({ scriptLoaded: false })
    }

    handleScriptError() {
      this.setState({ scriptError: true })
    }

    async handleScriptLoad() {
      this.setState({ scriptLoaded: true })
      await window.elli.script.guest.create()
      const tpoApplication = await window.elli.script.getObject('tpoApplication')
      // if (this.props.match.params.id) {
      //     try {
      //         let authCode = await tpoApplication.createAuthCode("Basic "+btoa('3c5hwwa8'))
      //         console.log(authCode.toString())
      //     } catch (error) {
      //         console.log(error.toString())
      //     }
      // }
      const userData = await tpoApplication.getUserData()
      const loanData = await tpoApplication.getLoanData()

      if (process.env.REACT_APP_TPO_ENV !== 'production') {
        console.log(JSON.stringify(loanData))
      }

      let tpo_api_url = 'https://9250-216-98-10-35.ngrok.io/v1/tpo/tpo_connect/loan_mapping' // Set ngrok here for local testing
      if (process.env.REACT_APP_TPO_ENV === 'development') {
        tpo_api_url = 'https://dev-service.reggora.com/v1/tpo/tpo_connect/loan_mapping'
      } else if (process.env.REACT_APP_TPO_ENV === 'staging') {
        tpo_api_url = 'https://staging-service.reggora.com/v1/tpo/tpo_connect/loan_mapping'
      } else if (process.env.REACT_APP_TPO_ENV === 'production') {
        tpo_api_url = 'https://prod-service.reggora.com/v1/tpo/tpo_connect/loan_mapping'
      }

      if (JSON.stringify(loanData) === '{}') {
        this.setState({
          infoText: 'Unable to retrieve loan data, please select loan again.',
          spinnerVisibility: false,
        })
        return this.state.infoText
      }
      await fetch(tpo_api_url, {
        headers: new Headers({
          Authorization: 'Basic ' + btoa('username:password'),
          'Content-type': 'application/json',
        }),
        method: 'post',
        body: JSON.stringify({
          lender_secret: this.props.match.params.id,
          user: JSON.stringify(userData),
        }),
        crossDomain: true,
        mode: 'cors',
      })
        .then(response => response.json())
        .then(data => this.performLoanMapping(
          loanData, data.field_mappings, data.lender_id, data.user_id, data.user_token
        ))
        .catch(error => console.log(error))
    }

    async performLoanMapping(tpoData, lenderMapping, lenderId, userId, user_token) {
      const mapped_values = {}
      let tpo_api_url = 'https://9250-216-98-10-35.ngrok.io/v1/tpo/tpo_connect/loan_link'
      if (process.env.REACT_APP_TPO_ENV === 'development') {
        tpo_api_url = 'https://dev-service.reggora.com/v1/tpo/tpo_connect/loan_link'
      } else if (process.env.REACT_APP_TPO_ENV === 'staging') {
        tpo_api_url = 'https://staging-service.reggora.com/v1/tpo/tpo_connect/loan_link'
      } else if (process.env.REACT_APP_TPO_ENV === 'production') {
        tpo_api_url = 'https://prod-service.reggora.com/v1/tpo/tpo_connect/loan_link'
      }

      const dateTimeRetriever = function(key, value) {
        let a
        if (typeof value === 'string') {
          a = /\/Date\((-?\d*)(-\d{4})?\)\//.exec(value)

          if (a) {
            return new Date(+a[1])
          }
        }
        return value
      }
      // dates sometimes come in w/ the format /Date(123445566677)/ We need to parse them into a format we can understand
      tpoData = JSON.parse(JSON.stringify(tpoData), dateTimeRetriever)


      for (const [key, value] of Object.entries(lenderMapping)) {
        const split_key = key.split('__')
        let curr_elem = tpoData
        let field_found = true

        for (const curr_key of split_key) {
          if (!(curr_key in curr_elem)) {
            field_found = false
            break
          }

          // Applications data
          if (curr_key === 'Applications') {
            curr_elem = curr_elem[curr_key][0]
          } else if (curr_key === 'Contacts') {
            let contactType = split_key.pop()
            let contactName = 'Name'

            // We need to support both Name and ContactName fields from the loan data
            // Getting the ContactName field from the field mapping. Ex: Contacts__SELLERS_AGENT_ContactName
            const contactParts = contactType.split('_')
            if (contactParts.length === 3) {
              contactType = contactParts[0] + '_' + contactParts[1]
              contactName = contactParts[2]
            }

            const contact = tpoData.Contacts.find(elem => elem.ContactType === contactType)

            if (contact && contactName in contact) {
              mapped_values[value + ' Name'] = contact[contactName]
              mapped_values[value + ' Email'] = 'Email' in contact ? contact.Email : ''
              mapped_values[value + ' Phone'] = 'Phone' in contact ? contact.Phone : ''
            }
            field_found = false
            break
          } else {
            curr_elem = curr_elem[curr_key]

            // Fees and Residences are array of objects
            // Need to retrieve appropriate object for curr_elem based on condition
            if (Array.isArray(curr_elem)) {
              if (curr_key === 'Fees') {
                const feeObj = curr_elem.find(e => e.FeeType === 'AppraisalFee')
                if (feeObj) {
                  curr_elem = feeObj
                }
              } else if (curr_key === 'Residences') {
                const residenceObj = curr_elem.find(e => e.ApplicantType === 'Borrower' && e.ResidencyType === 'Current')
                if (residenceObj) {
                  curr_elem = residenceObj
                }
              } else if (curr_key === 'CustomFields') {
                // Custom field mapping format: CustomFields__{MortgageType}__{FieldId}
                // Ex:CustomFields__FHA__CX_REGGORA_FHACASEASSIGNMENT
                const mappedFields = key.split('__')
                if (mappedFields && mappedFields.length === 3) {
                  if ('MortgageType' in tpoData && tpoData.MortgageType === mappedFields[1]) {
                    const mappedField = mappedFields[2].replaceAll('_', '.')
                    const customFieldObj = curr_elem.find(f => f.FieldName === mappedField)
                    if (customFieldObj && 'StringValue' in customFieldObj) {
                      curr_elem = customFieldObj.StringValue
                      break
                    }
                  }
                }
              }
            }
          }
        }

        if (field_found) {
          mapped_values[value] = curr_elem
        }
      }

      await fetch(tpo_api_url, {
        headers: new Headers({
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + user_token,
        }),
        method: 'post',
        body: JSON.stringify({
          mapped_data: JSON.stringify(mapped_values),
          lender_id: lenderId,
          user_id: userId,
        }),
        crossDomain: true,
        mode: 'cors',
      })
        .then(response => response.json())
        .then(data => this.openUrl(data.loan_url))
        .catch(error => console.log(error))
    }

    async openUrl(url) {
      window.location.replace(url)
    }
}

export default TpoConnectContainer
