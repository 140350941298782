import React from 'react'
import './App.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import TpoConnectContainer from './components/TpoConnectContainer'

function App() {
  return (
    <div data-testid='main-component' className="App">
      <Router>
        <Switch>
          <Route exact path="/secret/:id" component={TpoConnectContainer} />
        </Switch>
      </Router>
    </div>
  )
}

export default App
